import { extendTailwindMerge } from 'tailwind-merge'
import config from 'tailwind.config'

export const twMergeCustom = extendTailwindMerge({
  classGroups: {
    'font-size': Object.keys(config.theme.extend.fontSize).map(
      (key) => `text-${key}`
    ),
  },
})
