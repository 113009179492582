module.exports = {
  content: [
    '../common/components/**/*.{js,jsx,ts,tsx}',
    '../common/constants/**/*.{js,jsx,ts,tsx}',
    './src/components/**/*.{js,jsx,ts,tsx}',
    './src/pages/**/*.{js,jsx,ts,tsx}',
    './src/app/**/*.{js,jsx,ts,tsx}',
    './src/ui/**/*.{js,jsx,ts,tsx}',
    './src/**/*.{js,jsx,ts,tsx}',
  ],
  corePlugins: {
    container: false,
  },
  safelist: [
    'bg-brand-800',
    'bg-green-700',
    'bg-red-800',
    'bg-red-600',
    'border-brand-800',
    'rounded-tl-none',
    'rounded-tr-none',
    'rounded-bl-xl',
    'rounded-br-xl',
    'rounded-xl',
  ],
  theme: {
    extend: {
      // ***************************** //
      // ********** Colors *********** //
      // ***************************** //
      colors: {
        'gradient-1': '#291187',
        'gradient-2': '#3f25a9',
        'gradient-3': '#5f42d2',
        'gradient-4': '#9680ec',
        'gradient-5': '#7b62df',
        'gradient-6': '#b3a5ee',
        'neutral-black-100': '#000212',
        'neutral-black-80': '#000212',
        'neutral-black-64': '#000212',
        'neutral-black-48': '#000212',
        'neutral-black-32': '#000212',
        'neutral-black-24': '#000212',
        'neutral-black-16': '#000212',
        'neutral-black-8': '#000212',
        'neutral-black-4': '#000212',
        'neutral-white-100': '#ffffff',
        'neutral-white-80': '#ffffff',
        'neutral-white-64': '#ffffff',
        'neutral-white-48': '#ffffff',
        'neutral-white-32': '#ffffff',
        'neutral-white-24': '#ffffff',
        'neutral-white-16': '#ffffff',
        'neutral-white-8': '#ffffff',
        'neutral-white-4': '#ffffff',
        'black-100': '#80818d',
        'black-200': '#686a78',
        'black-300': '#585a6a',
        'black-400': '#494b5b',
        'black-500': '#3a3c4e',
        'black-600': '#303244',
        'black-700': '#242638',
        'black-800': '#151625',
        'black-900': '#000212',
        'brand-100': '#eeecf6',
        'brand-200': '#e3dff4',
        'brand-300': '#d0c8f2',
        'brand-400': '#b3a5ee',
        'brand-500': '#9680ec',
        'brand-600': '#7b62df',
        'brand-700': '#5f42d2',
        'brand-800': '#3f25a9',
        'brand-900': '#291187',
        'gray-100': '#f3f4f9',
        'gray-200': '#efeff1',
        'gray-300': '#e7e7ea',
        'gray-400': '#dedfe1',
        'gray-500': '#d6d6d9',
        'gray-600': '#cdcdd1',
        'gray-700': '#b9bac1',
        'gray-800': '#ababb1',
        'gray-900': '#91939d',
        'green-100': '#E0FFF2',
        'green-200': '#B4FFE0',
        'green-300': '#74FFC5',
        'green-400': '#14F195',
        'green-500': '#00BE6F',
        'green-600': '#009758',
        'green-700': '#007D49',
        'green-800': '#004F2E',
        'green-900': '#002E1B',
        'red-100': '#ffeef1',
        'red-200': '#ffe3e8',
        'red-300': '#ffcdd6',
        'red-400': '#feafbd',
        'red-500': '#e58193',
        'red-600': '#b1495c',
        'red-700': '#cf6679',
        'red-800': '#9e3b4d',
        'red-900': '#7e2636',
        'blue-100': '#fafbff',
        'blue-200': '#f2f4ff',
        'blue-300': '#dce1ff',
        'blue-400': '#a6b4ff',
        'blue-500': '#7b91ff',
        'blue-600': '#2b4eff',
        'blue-700': '#506dff',
        'blue-800': '#213abf',
        'blue-900': '#162780',
        'text-white': '#ffffff',
        'text-medium': '#b9bac1',
        'text-light': '#80818d',
        'text-x-light': '#3a3c4e',
        'text-black': '#000212',
        transparent: 'transparent',
      },
      // ***************************** //
      // ******* Font Family ********* //
      // ***************************** //
      fontFamily: {
        'general-sans': ['var(--font-general-sans)', 'sans-serif'],
      },
      // ***************************** //
      // ********** Spacing ********** //
      // ***************************** //
      spacing: {
        0: '0',
        1: '1px',
        2: '0.125rem',
        3: '3px',
        4: '0.25rem',
        8: '0.5rem',
        12: '0.75rem',
        16: '1rem',
        20: '1.25rem',
        24: '1.5rem',
        28: '1.75rem',
        32: '2rem',
        40: '2.5rem',
        48: '3rem',
        56: '3.5rem',
        64: '4rem',
        72: '4.5rem',
        80: '5rem',
        96: '6rem',
        112: '7rem',
        120: '7.5rem',
        128: '8rem',
        144: '9rem',
        160: '10rem',
        240: '15rem',
        320: '20rem',
        480: '30rem',
        560: '35rem',
        640: '40rem',
        1040: '65rem',
        '1/2': '50%',
        full: '100%',
        'min-content': 'min-content',
      },
      // ***************************** //
      // ********** Max Width ********** //
      // ***************************** //
      maxWidth: ({ theme }) => ({
        ...theme('spacing'),
      }),
      // ***************************** //
      // ********** Max Width ********** //
      // ***************************** //
      minWidth: ({ theme }) => ({
        ...theme('spacing'),
      }),
      // ***************************** //
      // ********* Font Size ********* //
      // ***************************** //
      fontSize: {
        'display-88': [
          '5.5rem',
          {
            letterSpacing: '-0.04em',
            lineHeight: '1em',
          },
        ],
        'display-72': [
          '4.5rem',
          {
            letterSpacing: '-0.04em',
            lineHeight: '1em',
          },
        ],
        'display-64': [
          '4rem',
          {
            letterSpacing: '-0.04em',
            lineHeight: '1.125em',
          },
        ],
        'display-56': [
          '3.5rem',
          {
            letterSpacing: '-0.02em',
            lineHeight: '1.143em',
          },
        ],
        'display-44': [
          '2.75rem',
          {
            letterSpacing: '-0.04em',
            lineHeight: '1.27em',
          },
        ],
        'heading-32': [
          '2rem',
          {
            letterSpacing: '-0.04em',
            lineHeight: '1.25em',
          },
        ],
        'heading-24': [
          '1.5rem',
          {
            lineHeight: '1.5em',
          },
        ],
        'title-20': [
          '1.25rem',
          {
            lineHeight: '1.6em',
          },
        ],
        'subheader-16': [
          '1rem',
          {
            lineHeight: '1.75em',
          },
        ],
        'body-16': [
          '0.875rem',
          {
            lineHeight: 'calc(24em/14)',
          },
        ],
        'body-14': [
          '0.875rem',
          {
            lineHeight: '1.5em',
          },
        ],
        'caption-12': [
          '0.75rem',
          {
            lineHeight: 'calc(20em/12)',
          },
        ],
      },
      // ***************************** //
      // ********* Font Weight *********** //
      // ***************************** //
      fontWeight: {
        regular: 450,
        medium: 520,
        bold: 600,
      },
      // ***************************** //
      // ********* Opacity *********** //
      // ***************************** //
      opacity: {
        0: '0',
        20: '0.2',
        40: '0.4',
        60: '0.6',
        80: '0.8',
        100: '1',
      },
      // ***************************** //
      // ********* Shadow *********** //
      // ***************************** //
      boxShadow: {
        'focus-white': '0 0 0 rgba(255, 255, 255, 0.47999998927116394)',
        'focus-brand': '0 0 0 rgba(150, 128, 236, 0.47999998927116394)',
        'focus-green': '0 0 0 rgba(3, 218, 197, 0.47999998927116394)',
        'focus-red': '0 0 0 rgba(207, 102, 121, 0.47999998927116394)',
        'general-1': '0 1px 2px rgba(0, 0, 0, 0.6399999856948853)',
        'general-2': '0 4px 8px rgba(0, 0, 0, 0.5600000023841858)',
        'general-3': '0 8px 12px rgba(0, 0, 0, 0.5600000023841858)',
        'general-4': '0 14px 24px rgba(0, 0, 0, 0.5600000023841858)',
        'general-5': '0 20px 40px rgba(0, 0, 0, 0.5600000023841858)',
        'general-6': '0 32px 56px rgba(0, 0, 0, 0.5600000023841858)',
        'general-7': '0 48px 88px rgba(0, 0, 0, 0.5600000023841858)',
        'general-8': '0 72px 112px rgba(0, 0, 0, 0.5600000023841858)',
        'hover-white': '0 4px 40px rgba(255, 255, 255, 0.20000000298023224)',
        'hover-brand': '0 4px 40px rgba(80, 63, 205, 0.8999999761581421)',
      },
      keyframes: {
        appear: {
          '0%': { opacity: 0, transform: 'scale(.8)' },
          '100%': { opacity: 1, transform: 'scale(1)' },
        },
        'appear-smooth': {
          '0%': { opacity: 0 },
          '100%': { opacity: 1 },
        },
        'spin-500': {
          to: {
            transform: 'rotate(360deg)',
          },
        },
        'swipe-in-from-right': {
          '0%': {
            transform: 'translateX(100%)',
          },
          '100%': {
            transform: 'translateX(0)',
          },
        },
        swipe: {
          from: {
            transform: 'translateX(0)',
          },
          to: {
            transform: 'translateX(-100%)',
          },
        },
      },
      animation: {
        appear: 'appear .5s ease-in',
        'appear-smooth': 'appear-smooth 200ms ease-in',
        'spin-500': 'spin-500 500ms linear infinite',
        'swipe-in-from-right': 'swipe-in-from-right .2s ease-in',
        swipe: 'swipe 20s linear infinite',
      },
    },
  },
  plugins: [require('@tailwindcss/typography')],
}
