'use client'

import { cva } from 'class-variance-authority'
import Link from 'next/link'
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
  ReactNode,
  Ref,
} from 'react'

import LoadingSpinner from '@components/ui/loading-spinner'
import { twMergeCustom } from '@utils/common/custom-tailwind-merge'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children?: ReactNode
  size?: 56 | 48 | 40 | 32
  intent?: 'primary' | 'secondary' | 'tertiary'
  disabled?: boolean
  href?: string | undefined
  className?: string
  fullWidth?: boolean
  iconOnly?: boolean
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  absoluteIcon?: ReactNode
  isProcessing?: boolean
}

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  children?: ReactNode
  size?: 56 | 48 | 40 | 32
  intent?: 'primary' | 'secondary' | 'tertiary'
  disabled?: boolean
  href?: string | undefined
  className?: string
  fullWidth?: boolean
  iconOnly?: boolean
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  absoluteIcon?: ReactNode
  isProcessing?: boolean
}

type Props = ButtonProps | AnchorProps

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  (
    {
      children = null,
      size = 48,
      intent = 'primary',
      disabled = false,
      href,
      className = '',
      fullWidth = false,
      iconOnly = false,
      leftIcon,
      rightIcon,
      absoluteIcon,
      isProcessing = false,
      onClick,
      ...otherProps
    },
    ref
  ) => {
    const buttonClasses = cva(
      `rounded font-bold text-text-white transition-all duration-300 ease-in-out inline-block focus:shadow-focus-white focus:outline-black-200 focus:outline focus:outline-2`,
      {
        variants: {
          intent: {
            primary: 'bg-gradient-primary hover:shadow-hover-brand',
            secondary:
              'bg-black-600 hover:shadow-hover-white border border-black-500',
            tertiary:
              'bg-transparent hover:bg-black-700 border border-transparent',
          },
          size: {
            56: 'py-12 px-24 text-title-20 h-56',
            48: 'py-[10px] px-16 text-subheader-16 h-48',
            40: 'py-8 px-12 text-body-16 h-40',
            32: 'py-[6px] px-8 text-caption-12 h-32',
          },
          disabled: {
            true: 'bg-gradient-none !bg-black-600 !text-text-black cursor-not-allowed hover:shadow-none',
            false: 'cursor-pointer',
          },
          fullWidth: {
            true: 'w-full text-center',
          },
          iconOnly: {
            true: 'p-[14px]',
          },
          absolueIconPresent: {
            true: 'relative',
          },
          isProcessing: {
            true: 'pointer-events-none bg-gradient-none bg-black-600 text-text-black',
          },
        },
        compoundVariants: [
          {
            size: 56,
            iconOnly: true,
            className: '!p-[14px]',
          },
          {
            size: 48,
            iconOnly: true,
            className: '!p-12',
          },
          {
            size: 40,
            iconOnly: true,
            className: '!p-[10px]',
          },
          {
            size: 32,
            iconOnly: true,
            className: '!p-[7px]',
          },
        ],
      }
    )

    const iconClasses = cva('', {
      variants: {
        size: {
          56: 'text-[22px]',
          48: 'text-[18px]',
          40: 'text-[18px]',
          32: 'text-[16px]',
        },
      },
    })

    const iconFlexClasses = cva('flex items-center justify-center ', {
      variants: {
        size: {
          56: 'gap-12',
          48: 'gap-8',
          40: 'gap-8',
          32: 'gap-4',
        },
      },
    })

    const iconOnlyClasses = cva('', {
      variants: {
        size: {
          56: 'text-[28px]',
          48: 'text-[24px]',
          40: 'text-[20px]',
          32: 'text-[18px]',
        },
      },
    })

    const spinnerSize = () => {
      switch (size) {
        case 56:
          return 28
        case 48:
          return 24
        case 40:
          return 20
        case 32:
          return 18
      }
    }

    const absolueIconPresent = !!absoluteIcon

    if (href) {
      return (
        <Link href={href} ref={ref as Ref<HTMLAnchorElement>} legacyBehavior>
          <a
            {...(otherProps as any)}
            className={twMergeCustom(
              `${buttonClasses({
                intent,
                size,
                disabled,
                fullWidth,
                iconOnly,
                absolueIconPresent,
                isProcessing,
              })}`,
              className
            )}
          >
            {absoluteIcon && (
              <div
                className={`absolute left-16 top-1/2 -translate-y-1/2 ${iconClasses(
                  {
                    size,
                  }
                )}`}
              >
                {absoluteIcon}
              </div>
            )}
            <span
              className={
                rightIcon || leftIcon || isProcessing
                  ? iconFlexClasses({ size })
                  : ''
              }
            >
              {leftIcon && (
                <div className={`${iconClasses({ size })}`}>{leftIcon}</div>
              )}

              <div className={iconOnly ? iconOnlyClasses({ size }) : ''}>
                {iconOnly && isProcessing ? (
                  <LoadingSpinner size={spinnerSize()} />
                ) : (
                  children
                )}
              </div>
              {rightIcon && (
                <div className={`${iconClasses({ size })}`}>{rightIcon}</div>
              )}
              {isProcessing && !rightIcon && !iconOnly && (
                <div className={`${iconClasses({ size })}`}>
                  <LoadingSpinner size={spinnerSize()} />
                </div>
              )}
            </span>
          </a>
        </Link>
      )
    }

    return (
      <button
        {...(otherProps as any)}
        onClick={!disabled && !isProcessing ? onClick : undefined}
        className={twMergeCustom(
          `${buttonClasses({
            intent,
            size,
            disabled,
            fullWidth,
            iconOnly,
            absolueIconPresent,
            isProcessing,
          })}`,
          className
        )}
        disabled={disabled || isProcessing}
        ref={ref as Ref<HTMLButtonElement>}
      >
        {absoluteIcon && (
          <div
            className={`absolute left-16 top-1/2 -translate-y-1/2 ${iconClasses(
              {
                size,
              }
            )}`}
          >
            {absoluteIcon}
          </div>
        )}
        <span
          className={
            rightIcon || leftIcon || isProcessing
              ? iconFlexClasses({ size })
              : ''
          }
        >
          {leftIcon && (
            <div className={`${iconClasses({ size })}`}>{leftIcon}</div>
          )}
          <div className={iconOnly ? iconOnlyClasses({ size }) : ''}>
            {iconOnly && isProcessing ? (
              <LoadingSpinner size={spinnerSize()} />
            ) : (
              children
            )}
          </div>
          {rightIcon && (
            <div className={`${iconClasses({ size })}`}>{rightIcon}</div>
          )}
          {isProcessing && !rightIcon && !iconOnly && !iconOnly && (
            <div className={`${iconClasses({ size })}`}>
              <LoadingSpinner size={spinnerSize()} />
            </div>
          )}
        </span>
      </button>
    )
  }
)

Button.displayName = 'Button'

export default Button
